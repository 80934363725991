$primary-color: #646464;
$theme-color: #000066;
$theme1-color: #6B0533;
$theme2-color: #02ADE6;
$theme3-color: #DC411C;

$themeLight-color : #C8C8C8;
$themeLight1-color : #F0F0F0;
$themeLight2-color : #E6E6E6;


@mixin theme($theme) {
  background-color: $theme;
  border: 1px solid $theme;
}

// @mixin background($imgpath, $position:center center, $repeat: no-repeat) {
//   background: {
//     image: url($imgpath);
//     position: $position;
//     repeat: $repeat;
//   }
// }

// .testing {
//   @include background('/my/img/path.png');
// }
@mixin flex-center($align: center, $justify: center, $wrap: wrap) {
  display: flex;
  flex-wrap: $wrap;
  align-items: $align;
  justify-content: $justify;
}

@mixin absolute-all($right: 0, $bottom: 0) {
  position: absolute;
  top: 0;
  left: 0;
  right: $right;
  bottom: $bottom;
}

@mixin img-all($fit: contain, $position: center center) {
  width: 100%;
  height: 100%;
  object-fit: $fit;
  object-position: $position;
}

@mixin theme-svg($color) {
  color: $color;

  div {
    color: $color;
  }

  &:hover {
    .hex {
      path {
        stroke: $color;
        stroke-width: 3px;
        fill: rgba($color, .20);
      }
    }
  }
}

@mixin themeBg-svg($color) {

  // pointer-events: none;
  .hex-bg {
    z-index: 1;
    position: relative;

    path {
      fill: $color  !important;
    }
  }
}

%flex-all {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

%flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
