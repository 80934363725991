/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "variable.scss";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,700&display=swap');

@import '~primeng/resources/themes/luna-blue/theme.css';
@import '~primeng/resources/primeng.min.css';

body {
  font-family: 'Roboto', sans-serif;
  color: $primary-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.172;
}

h1 {
  font-size: calc(2.72rem + 1.5vw);
  margin-bottom: 4.5rem;
}

h1.small {
  font-size: calc(2.36rem + 1.5vw);
}

h2 {
  font-size: calc(1.375rem + 1.5vw);
  line-height: 1.175;
}

h3 {
  font-size: calc(1.285rem + 0.3vw);
  line-height: 1.154;
  font-weight: 700;

}

h4 {
  font-size: calc(1.183rem + 0.3vw);
}

ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

a,
.btn {
  transition: all .5s;
}

p:last-child {
  margin-bottom: 0;
}

hr {
  border-color: #E6E6E6;
  opacity: 1;
}

// custom class
.mb-80 {
  margin-bottom: 80px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.g-16 {
  --bs-gutter-x: 16px;
  --bs-gutter-y: 16px;
}

.g-20 {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}

.g-40 {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 40px;
}

.g-50 {
  --bs-gutter-x: 50px;
  --bs-gutter-y: 50px;
}

.bg-theme {
  background: $theme-color;
}

.text-theme {
  color: $theme-color
}

.text-theme1 {
  color: $theme1-color
}

.text-theme2 {
  color: $theme2-color
}

.text-theme3 {
  color: $theme3-color
}

.mw-440 {
  max-width: 440px;
}

.mw-1200 {
  max-width: 1200px;
}

.minH-580 {
  min-height: 580px;
}

.btn {
  @extend %flex-all;
  color: $primary-color;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  padding: 13px 29px 11px 29px;
  border-radius: 8px;
}

.btn:not(:last-child) {
  margin-right: 10px;
}

.btn-white {
  @include theme($theme: rgba(var(--bs-white-rgb)));
  color: $theme-color;

  &:hover {
    color: rgba(var(--bs-white-rgb));
    background-color: transparent;
  }
}

.btn-theme {
  @include theme($theme: $theme-color);
  color: rgba(var(--bs-white-rgb));

  &:hover {
    color: $theme-color;
    background-color: transparent;
  }
}

.btn-theme1 {
  @include theme($theme: $theme1-color);
  color: $themeLight1-color;

  &:hover {
    color: $theme1-color;
    background-color: transparent;
  }
}


.btn-themePrimary {
  @include theme($theme: $primary-color);
  color: $themeLight1-color;

  &:hover {
    color: $primary-color;
    background-color: transparent;
  }
}

.btn-themelight {
  @include theme($theme: $themeLight-color);
  color: $themeLight2-color;

  &:hover {
    color: $themeLight-color;
    background-color: transparent;
  }
}

.btn-themelight1 {
  @include theme($theme: $themeLight1-color);
  color: $primary-color;

  &:hover {
    color: $themeLight1-color;
    background-color: $primary-color;
  }
}

.btn-icon {
  padding: 12px;
}

.btn-iconText i {
  margin-right: 6px;
}

.btn-icon i,
.btn-iconText i {
  font-size: 16px;
}

.btn.btn-icon {
  padding: 11px;
}

.btn.btn-iconText {
  padding: 12px 27px 10px 27px;
}

label.label-custom {
  line-height: 1.17;
  font-weight: 700;
  color: #AAAAAA;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.label-small {
  font-size: 16px;
  font-weight: 300;
  color: #AAAAAA;
}

.form-control.form-custom {
  color: $primary-color;
  font-size: 24px;
  line-height: 1.21;
  font-weight: 400;
  padding: 5px 0;
  border: 0;
  border-bottom: 1px solid #DCDCDC;
  border-radius: 0;

  background: transparent;

  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }

  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
    opacity: 0;
  }
}

.form-control.form-custom,
.form-control.form-custom:focus {
  background: transparent;
  box-shadow: none;
}

.form-floating.floating-custom {
  text-align: left;

  >label.label-custom {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
    padding: 0 5px;
    text-transform: none;
    color: $primary-color;
    margin: 0;
    background: rgba(var(--bs-white-rgb));
    width: auto;
    height: auto;
    top: 14px;
    left: 15px;
    opacity: 1;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .form-control.form-custom {
    // font-size: 20px;
    padding: 0 21px;
    border: 1px solid $primary-color;
    border-radius: 8px;
    height: 60px
  }

  >.form-control.form-custom:focus~label,
  >.form-control.form-custom:not(:placeholder-shown)~label {
    transform: scale(0.7) translateY(-2.3rem) translateX(0.45rem);
  }
}

.bg-theme .form-floating.floating-custom {
  text-align: left;

  >label.label-custom {
    color: rgba(var(--bs-white-rgb));
    background: $theme-color;
  }
}

.styled-select {
  width: 100%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url(assets/images/site/select-icon.png) no-repeat right white;
  border-bottom: 1px solid #DCDCDC;
  display: inline-block;
  position: relative;
  width: 100%;

  select.form-control.form-custom {
    background: transparent;
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    padding-left: 10px;
  }
}

.styled-ngselect .ng-select {
  .ng-select-container {
    border: 0;
    border-bottom: 1px solid #DCDCDC;
    border-radius: 0;
    height: 40px;
    color: #646464;
    font-size: 24px;
    line-height: 1.21;
    font-weight: 300;
    padding: 5px 0;

    .ng-arrow-wrapper {
      width: auto;
      padding-right: 0;

      .ng-arrow {
        border-color: #aaa transparent transparent;
        border-width: 6px 6px 2px;
      }
    }

  }


  .ng-select-opened>.ng-select-container .ng-arrow {
    border-width: 0 6px 6px;

  }

  .ng-dropdown-panel .ng-option-label {
    color: #646464;
    font-weight: 300;
  }

}

.form-check {
  padding-left: 34px;



  .form-check-input {
    width: 22px;
    height: 22px;
    margin-top: 0;
    margin-left: -34px;
    border-color: #AAAAAA;
    cursor: pointer;

    &:checked {
      background-color: $primary-color;

      ~label {
        color: $primary-color;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }


  label {
    margin: 0;
    cursor: pointer;
    color: #AAAAAA;
    font-weight: 700;
    text-transform: uppercase;
  }

}

.form-check.form-switch {
  padding-left: 50px;

  .form-check-input {
    width: 45px;
    margin-left: -50px;
  }
}

.a-link {
  font-size: 14px;
  line-height: 1.143;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $theme-color;
  }
}

.text-themeLight,
.text-white {
  color: $themeLight-color;

  .form-control.form-custom {
    border-color: $themeLight-color;
    color: rgba(var(--bs-white-rgb));

  }

  .a-link {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}



.main-content {
  padding: 67px 0;
  flex-grow: 1 !important;
}

.table {
  color: #646464;
}

table.table-custom {
  font-size: 14px;
  line-height: 1.143;
  font-weight: 400;
  border-radius: 8px;
  overflow: hidden;
  border-color: #EBEBEB;
  margin-bottom: 0;

  th {
    padding: 22px 20px 21px;
    vertical-align: middle;
    background: #FAFAFA;
    border: 1px solid #E6E6E6;
    font-weight: 400;

  }

  td {
    padding: 7px 20px 6px;
    vertical-align: middle;

  }

  .text-small {
    font-size: 8px;
    color: #AAAAAA;

    th {
      padding: 5px 10px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
  }
}

.border-box {
  border: 1px solid #E6E6E6;
  border-radius: 8px;
}

.home-block {
  .col-3 {
    width: 29.50%;
  }

  .col-6 {
    width: 40.99%;
  }

  .border-box {
    text-transform: uppercase;
    padding: 25px 30px;
  }

  p {
    font-size: 12px;
    line-height: 1.17;
    font-weight: 700;
    letter-spacing: 0.2em;
    color: #6B0533;
    margin-bottom: 5px;
  }
}

svg.hex {
  width: 253px;
  height: 221px;

  path {
    stroke: #E6E6E6;
    transition: all .5s;
  }
}

.theme-skyblue {
  @include theme-svg(#02ADE6);
}

.theme-brown {
  @include theme-svg(#6B0533);
}

.theme-blue {
  @include theme-svg(#000066);
}

.theme-orange {
  @include theme-svg(#DC411C);
}

.themeBg-skyblue {
  @include themeBg-svg(#02ADE6);
}

.themeBg-brown {
  @include themeBg-svg(#6B0533);
}

.themeBg-blue {
  @include themeBg-svg(#000066);
}

.themeBg-orange {
  @include themeBg-svg(#DC411C);
}

.themeBg-gray {
  @include themeBg-svg(#646464);
}


.pointer-none {
  pointer-events: none;
}

.filelabel {
  @include flex-center;
  width: 100%;
  height: 130px;
  background: #FAFAFA;
  border-radius: 8px;
  padding: 5px;
  transition: background 300ms ease;
  cursor: pointer;
  margin: 0;

  i {
    font-size: 30px;
    color: #EBEBEB;
  }

  &:hover {
    background: #C8C8C8;
  }

  input.file-upload {
    display: none;
  }
}

.file-content {
  >div {
    @include flex-center(center, space-between);

    &>div:first-child{
        @include flex-center(center, flex-start);
    }
  }

  >div:not(:last-child) {
    margin-bottom: 10px;
  }

  img {
    max-height: 38px;
    margin-right: 14px;
  }

  h4 {
    margin-bottom: 0;
    font-weight: 400;
  }
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #bbb #fcfcfc;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fcfcfc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bbb;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
}

body {
  scrollbar-width: thin;
  scrollbar-color: #000066 #f1f1f1;
}

body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  position: absolute;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #000066;
}

body::-webkit-scrollbar-track,
body::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

.p-dialog .p-dialog-header,
.modal-header {
  color: #646464;
  background: #FAFAFA;
  padding: 16px 20px;
  border-bottom: 1px solid #E6E6E6;
}

.btn-danger {
  color: #fff;
}

.btn-danger:hover,
.btn-danger:focus {
  background: transparent;
  color: #b02a37;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 20px;
}

.p-dialog .p-dialog-content,
.modal-body {
  padding: 20px 20px;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

table.table-skill {
  margin: 0;

  th {
    border: 0;
    padding: 0 10px 8px;
    font-size: 16px;
    line-height: 1.2;
    white-space: nowrap;

    &:first-child {
      padding-left: 0;
    }
  }

  td {
    padding: 6px 10px;
    font-size: 16px;
    line-height: 1.17;
    vertical-align: middle;

    &:first-child {
      width: 60px;
      padding-left: 0;
      text-align: center;
    }
  }
}

// responsive start
@media (min-width: 1200px) {
  .container {
    max-width: 1384px;
  }

  h1 {
    font-size: 5rem;
  }

  h1.small {
    font-size: 4.375rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.625rem;
  }

  h4 {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .container.container-small {
    max-width: 864px;
  }
}

@media (max-width: 1199px) {
  table.table-custom {

    th {
      padding: 22px 12px 21px;

    }

    td {
      padding: 7px 12px 6px;

    }

    .text-small {

      th {
        padding: 5px 10px;
      }
    }
  }

  .home-block {
    .col-3 {
      width: 26%;
    }

    .col-6 {
      width: 48%;
    }
  }
}

@media (max-width: 991px) {
  .home-block {
    .col-3 {
      width: 50%;
      order: 1;
    }

    .col-6 {
      width: 100%;
      order: 2;
    }
  }
}

@media (max-width: 767px) {
  h1 {
    margin-bottom: 1.8rem;
  }

  h5 {
    font-size: 18px;
  }

  .form-control.form-custom {
    font-size: 18px;
    line-height: 1.4;
  }

  .form-floating.floating-custom {
    label.label-custom {
      font-size: 18px;
      left: 10px;
      top: 10px;
    }

    .form-control.form-custom {
      height: 45px;
      padding: 0 15px;
    }

    >.form-control.form-custom:focus~label,
    >.form-control.form-custom:not(:placeholder-shown)~label {
      transform: scale(0.7) translateY(-1.7rem) translateX(0.45rem);
    }
  }

  .btn {
    padding: 10px 15px 8px 15px;
  }

  .btn.btn-icon {
    padding: 10px;
  }

  .btn.btn-iconText {
    padding: 10px 15px 9px 15px
  }

  .btn-icon i,
  .btn-iconText i {
    font-size: 14px;
  }

  .main-content {
    padding: 50px 0;
  }

  .home-block {
    &.mb-80 {
      margin-bottom: 35px;
    }

    .border-box {
      padding: 12px 15px;
      text-transform: none;
    }
  }

  .form-check.form-switch {
    padding-left: 45px;

    .form-check-input {
      width: 40px;
      height: 19px;
      margin-left: -45px;
    }
  }

  .table-mob-custom {
    th {
      display: none;
    }

    td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 3px 10px 5px;

      &:before {
        content: attr(table-head);
        text-align: left !important;
        margin-right: 10px;
      }
    }
  }

  .p-dialog .p-dialog-header {
    padding: 10px 15px;
  }

  .p-dialog .p-dialog-content {
    padding: 15px;
  }

  table.table-skill {
    &.table-mob-custom td:before {
      font-weight: 700;
    }

    td {
      align-items: flex-start;
      border: 0;
      padding: 2px 0;

      &:last-child {
        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 5px;
        margin-bottom: 7px;
        min-width: auto;
      }
    }

    tr:last-child {
      td:last-child {
        border: 0;
        padding-bottom: 2px;
        margin-bottom: 0;
      }
    }
  }

  .p-dialog {
    .form-check label {
      text-transform: none;
    }
  }
}

@media (max-width: 575px) {
  .table-mob-custom {
    td {
      &:before {
        margin-right: 7px;
      }
    }
  }
}

@media (max-width: 374px) {
  .home-block {
    .col-3 {
      width: 1000%;
      order: inherit;
    }
  }
}
